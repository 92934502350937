import { Component } from '@angular/core';
import { DisableRightClickService } from './modules/service/disable-right-click.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'smcportal';
  
  constructor(private rightClickDisable: DisableRightClickService) {}
  ngOnInit() {
    // this.rightClickDisable.disableRightClick();

  } 
}


