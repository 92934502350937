import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-stickerlistings',
  templateUrl: './stickerlistings.component.html',
  styleUrls: ['./stickerlistings.component.scss']
})
export class StickerlistingsComponent implements OnInit {

  userdata;
  stickerall:any=[];
  searchdata;

  length: number;
  pageSize: number= 10;
  pageSizeOptions = [ 10, 20,50,100];



  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource();
  displayedColumns = ['no','sticker_number' ,'agent_id', 'client_id', 'legacy_policy_number', 'policy_number_nic','inception_date',
  'expiry_date','vehicle_number','date_modified']

  // <td>{{sticker.sticker_number}}</td>
  // <td>{{sticker.agent_id}}</td>
  // <td>{{sticker.client_id}}</td>s
  // <td>{{sticker.legacy_policy_number}}</td>
  // <td>{{sticker.policy_number_nic}}</td>
  // <td>{{sticker.inception_date}}</td>
  // <td>{{sticker.expiry_date}}</td>
  // <td>{{sticker.vehicle_number}}</td>
  // <td>{{sticker.managerEmail}}</td>
  // <td>{{sticker.created_at}}</td>
  // <td>{{sticker.date_modified}}</td>
    

  constructor(public service:DashboardService, public router:Router) { }

  ngOnInit() {

    this.userdata=JSON.parse(localStorage.getItem('userdata'))
    this.service.getAllstickers(this.userdata.email, this.userdata.role).subscribe(data=>{
      this.stickerall = data['responseData'];     
      //this.searchdata = this.quotes;

      this.dataSource.data = data['responseData']; 

      console.log("datasource"+  this.dataSource )
      this.dataSource.paginator = this.paginator;
      
  })
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //  }

  search (query: string){
    this.searchdata = (query) ?this.stickerall.filter( data => data.client_id.toLowerCase().includes(query.toLowerCase()) ||data.policy_number_nic.toLowerCase().includes(query.toLowerCase()) || data.vehicle_number.toLowerCase().includes(query.toLowerCase()) || data.expiry_date.toUpperCase().includes(query.toUpperCase())) : this.stickerall;
  }

  viewregistered(){
    this.router.navigate(['/registeredagents']);
  }
  
  registeragents(){
    this.router.navigate(['/agentadmission'])
  }
  
  stickerlistings(){
    this.router.navigate(['/stickerlistings']);
  }

}
