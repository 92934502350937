import { Injectable } from '@angular/core';
import {Chart} from 'chart.js'
//import * as Chart from 'chart.js'

@Injectable()
export class TpagtService {

  chart: Chart;
  constructor(){}
  
  getTopPerformChart(labels, total,canvasid){
    this.chartConfig();
      const canvas: any = document.getElementById('topperformingagent');
    const ctx = canvas.getContext('2d');

    // if (ctx) {
    //   console.log("vsfkhgs")
    //   this.chart.destroy();
    // }

    return new Chart(ctx, {
      type: 'roundedBar',
      data: {
        labels: labels, // your labels array

      //  labels: [
      //   '2016-12-5', 
      //   '2016-12-4'
         
        
      // ],
        datasets: [
          {
            // pointRadius: 0.7, 
           
            data: total, // your data array
            //borderColor: 'background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);',
            // backgroundColor: [
            //   'rgba(200, 200, 235, 1)',
            //   'rgba(255, 99, 132, 1)',
            //   'rgba(75, 192, 192, 1)',
            //   'rgba(153, 102, 255, 1)',
            //   'rgba(255, 159, 64, 1)',
            //   'rgba(94, 107, 46, 1)',
            //   'rgba(107, 46, 58, 1)',
            //   'rgba(107, 46, 58, 1)',
            //   'rgba(208, 103, 152, 1)'
            // ],
            backgroundColor: ['#f8a351',
            '#f97953',
            '#52bec8',
            '#46a4de',
            '#0890bf',
          ],


           
            barThickness:35,
            
            fill: true,
          },
          
          {
           // data: canvasid, // your data array
            borderColor: '#00AEFF',

            //fill: false
          },
        ]
      },
      options: {
        
        //  cornerRadius: 23,
        // barThickness:50,
       barRoundness: 1.2,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            
            // offset: true ,
            //  categoryPercentage: -0.15,
            //  barPercentage: 4,
             gridLines: {
              drawBorder: false, //<- set this
              color: "rgba(0, 0, 0, 0)",
              display: false ,
             
           },
           ticks: {
            fontFamily :'gotham',
            fontSize: 12,
            labelOffset :-15,
            //padding: 20,
           // fontColor: "rgba(0,0,0,0.5)",
            //fontStyle: "bold"
        }
           
          
          }],
          yAxes: [{
//display: true,
           // offset: true ,
           ticks: {
            //fontColor: "rgba(0,0,0,0.5)",
          //  fontfamily : "$font-primary",
            //fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 20,
            fontFamily :'gotham',
            fontSize: 12,
            // fontSize: 13
        },
       // display: true,
              //drawBorder: false,

              gridLines: {
                display: false ,
                drawBorder: false ,//<- set this
                color: "rgba(0, 0, 0, 0)",
               
                //drawTicks: false,
                 //   display: false,
             },
          }],
        }
      }


      
    });


    
}


chartConfig() {
  // draws a rectangle with a rounded top
  Chart.helpers.drawRoundedTopRectangle = function(
    ctx,
    x,
    y,
    width,
    height,
    radius
  ) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    // top right corner
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    // bottom right	corner
    ctx.lineTo(x + width, y + height);
    // bottom left corner
    ctx.lineTo(x, y + height);
    // top left
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  };
  // @ts-ignore
  const ChartEl: ChartElementsOptionsExtended = Chart.elements;
  // @ts-ignore
  const ChartCo: ChartControllers = Chart.controllers;
  // @ts-ignore
  ChartEl.RoundedTopRectangle = ChartEl.Rectangle.extend({
    draw: function() {
      const ctx = this._chart.ctx;
      const vm = this._view;
      let left, right, top, bottom, signX, signY, borderSkipped;
      let borderWidth = vm.borderWidth;

      if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
      } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
      }

      // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line
      if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        const barSize = Math.min(
          Math.abs(left - right),
          Math.abs(top - bottom)
        );
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        const halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        const borderLeft =
          left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        const borderRight =
          right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        const borderTop =
          top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        const borderBottom =
          bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
          left = borderLeft;
          right = borderRight;
        }
      }

      // calculate the bar width and roundess
      const barWidth = Math.abs(left - right);
      const roundness = this._chart.config.options.barRoundness || 0.5;
      const radius = barWidth * roundness * 0.5;

      // keep track of the original top of the bar
      const prevTop = top;

      // move the top down so there is room to draw the rounded top
      top = prevTop + radius;
      const barRadius = top - prevTop;

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // draw the rounded top rectangle
      Chart.helpers.drawRoundedTopRectangle(
        ctx,
        left,
        top - barRadius + 1,
        barWidth,
        bottom - prevTop,
        barRadius
      );

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }
      top = prevTop;
    }
  });

  Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);

  Chart.controllers.roundedBar = ChartCo.bar.extend({
    // @ts-ignore
    dataElementType: ChartEl.RoundedTopRectangle
  });
}



}





