import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import swal from 'sweetalert2'

@Component({
  selector: 'app-manager-resetpassword-success',
  templateUrl: './manager-resetpassword-success.component.html',
  styleUrls: ['./manager-resetpassword-success.component.scss']
})
export class ManagerResetpasswordSuccessComponent implements OnInit {

  constructor( private router:Router) { }

  ngOnInit() {
  }

  loginNow(){
    // swal.showLoading()
    swal.hideLoading()
    swal.fire({
     icon: 'success',
      text: 'Welcome to smartCover..Please login',
      timer: 1000,
     showConfirmButton: false
    }); 
    this.router.navigate(['']);

  }

}
