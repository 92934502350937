import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
import swal from 'sweetalert2';

interface Target {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-sendnotifications',
  templateUrl: './sendnotifications.component.html',
  styleUrls: ['./sendnotifications.component.scss']
})
export class SendnotificationsComponent implements OnInit {

  audience: Target[] = [
    {value: 'Agents', viewValue: 'Agents'},
    {value: 'Clients', viewValue: 'Clients'},
  ];



  selectedCode: string = "";
  selectedMessage: string = "";
  selectedSelect: string = "";

  notif:any=[];

  onChangeCode(code: string) {
     this.selectedCode = code;

     console.log("f " +  this.selectedCode )
  }




  onChangeMessage(code: string) {
    this.selectedMessage = code;

    console.log("fs " +  this.selectedMessage )
 }


 onChangeSelect(code: string) {
  this.selectedSelect = code;

  console.log("select " +  this.selectedSelect )
}

  sendmessageform: FormGroup;
  userdata;

  smsdb={
    email:'',
    message_title:'',
    message_text:'',
    activity:''
  }



  messages = [
    {
      from: 'Entity 1',
      subject: 'Message Subject 1',
      content: 'Message Content 1'
    },
    {
      from: 'Entity 2',
      subject: 'Message Subject 2',
      content: 'Message Content 2'
    },
    {
      from: 'Entity 3',
      subject: 'Message Subject 3',
      content: 'Message Content 3'
    },
    {
      from: 'Entity 4',
      subject: 'Message Subject 4',
      content: 'Message Content 4'
    },
  ]


  constructor(public router:Router, public service:DashboardService, public formBuilder: FormBuilder) {

    this.sendmessageform = this.formBuilder.group({
      message_title: new FormControl('',[Validators.required]),
      message_text: new FormControl('', [Validators.required]),
      activity: new FormControl('', [Validators.required])
    })

    this.userdata=this.service.currentUser();
   }

  ngOnInit() {
//get notifications
  this.service.getNotifications(this.userdata.code).subscribe(data=>{
    if(data['responseCode']==='000'){
      this.notif = data['responseData'];
     // this.dataSource = this.newc;

    }else if(data['responseCode']==='005'){
      // this.message = data['responseData']
    //  this.message = true;
    }

  })

  }

  sendSMS(){
  this.smsdb = this.sendmessageform.value;

  if(this.sendmessageform.valid) {
     this.smsdb = this.sendmessageform.value;
     this.smsdb.email = "appiah@gmail.com"
      var test = JSON.stringify(this.smsdb);

      // console.log(this.smsdb);
      this.service.sendSmstoDb(this.smsdb).subscribe(response=>{
        // console.log(response);
        if(response['responseCode'] ==='000'){
          this.sendmessageform = this.formBuilder.group({
            title: new FormControl('',[Validators.required]),
            message: new FormControl('', [Validators.required]),
            activity: new FormControl('', [Validators.required])

          })
          swal.fire("Success",response['responseMessage'], "success");
        }

      })
  }
  //alert(this.scheduler);



  }


   sendmessage(){
     if (!this.sendmessageform.valid) return swal.fire({title: 'Ooops ...',text: 'Some form items are item', footer:'Please, make the necessary changes and try again'})
      let message = {
          to : this.service.notifyBucket,
          content_available: true,
         notification: {
         title: this.sendmessageform.value.message_title,
              body: this.sendmessageform.value.message_text
           },
            data: {
              activity:this.sendmessageform.value.activity
           }
          }

         swal.showLoading()
          this.service.sendNotification(message).subscribe(data => {
            console.log();


            if (data['message_id']) {

              swal.fire({title: 'Success',text: 'Notification has been sent successfully'});

             //message to be sent to database here

            //swal.fire("Success",response['responseMessage'], "success");
           } else {
              swal.fire({title: 'Oops...',text: 'Notification sending failed.', footer: 'Please try again.'});
           }
        }, error => {
          swal.hideLoading()
            swal.fire({title: 'Oops...',text: error.message, footer: 'Please, make the necessary changes and try again.'});
     })


    }
}
