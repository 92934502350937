import { Injectable } from '@angular/core';
import {Chart} from 'chart.js'

@Injectable()
export class PolicyService {



  constructor(){}
  getPolicyChart(labels, amounts,canvasid){
    this.chartConfig();
    
    const canvas: any = document.getElementById('policytrans');
    const ctx = canvas.getContext('2d');

    var gradientFill = ctx.createLinearGradient(0, 0, 0, 170);

   // colors
  //  #005b97 , #0075be, #1e9cd7, #ccbfff

    // gradientFill.addColorStop(0, "rgba(110, 213, 249, 1)");
    // //gradientFill.addColorStop(.9, "rgba(179, 174, 244, 0.2)");
    // gradientFill.addColorStop(1, "rgba(179, 174, 244, 0.2)");


    gradientFill.addColorStop(0, "#005b97");
    gradientFill.addColorStop(0.25, "#0075be");
    gradientFill.addColorStop(0.5, "#1e9cd7");
    gradientFill.addColorStop(1, "#ccbfff");
    
  
   

    return new Chart(ctx, {
      type: 'roundedBar',
      data: {
       labels: labels,
        
        
        datasets: [
          {
            pointRadius: 0, 
            data: amounts,
            // your data array
          //  borderColor: 'background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);',

         
           // backgroundColor: '#46A0F2',
            backgroundColor:gradientFill,
            fill: true,
            barThickness:35,
          

          },
          {
            //data: canvasid, // your data array
            // borderColor: '#00AEFF',
            // fill: false
          },
        ]
      },
      options: {
        
        // tooltips: {
        //   intersect: false
        // },
        // animation: {
        //   duration: 1,
        //   onComplete: function() {
        //     var controller = this.chart.controller;
        //     var chart = controller.chart;
        //     var xAxis = controller.scales['x-axis-0'];
           
        //      var	numTicks = xAxis.ticks.length;        
        //     var xOffsetStart = xAxis.width / numTicks;
        //     var halfBarWidth = (xAxis.width / (numTicks * 2));
    
        //     xAxis.ticks.forEach(function(value, index) {
        //       var xOffset = (xOffsetStart * index) + halfBarWidth -6;
        //       var yOffset = chart.height + 1 ;          
        //       ctx.fillText(value, xOffset, yOffset);
        //     });
    
        //   }
        // },
        barRoundness: 1.2,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            //display: false,
            // barPercentage: -0.1,
            //// categoryPercentage:4,
            
            // unitStepSize: 4,
           
            // offset: true ,
            // type: 'categoryRightAligned',
            gridLines: {
             // offset: true ,
             display: false,
              //drawBorder: true ,//<- set this
              
           
          },
          ticks: {
            //barStrokeWidth : 2,
            fontFamily :'gotham',
            fontSize: 12,
            display: true,
            scaleBeginAtZero: false,
            labelOffset :-15
          // padding:2,
          
           // fontColor: "rgba(0,0,0,0.5)",
            //fontStyle: "bold"
        },
            // display: true
          }],
          yAxes: [{
            drawBorder: false,
            display: true,
            // categoryPercentage:-0.15,
            // barPercentage: 4,
            
            ticks: {
              fontFamily :'gotham',
             // fontSize: 12,
              beginAtZero: true,
                
              //padding: 20,
             // fontColor: "rgba(0,0,0,0.5)",
              //fontStyle: "bold"
              
          },
          gridLines: {
            //show xaxis lines 
            display: true ,
            drawBorder: false, 
            // drawBorder: false ,//<- set this
            // color: "rgba(0, 0, 0, 0)",
      
         },
            
          }],
        }
      },
      plugins: [{
        beforeDraw: function(chart) {
          //  var ctx = chart.ctx;
          //  var yAxis = chart.scales['y-axis-0'];
          //  var tickGap = yAxis.getPixelForTick(1) - yAxis.getPixelForTick(0);
          //  yAxis.options.ticks.fontColor = 'transparent'; // hide original tick
          //  // loop through ticks array
          //  Chart.helpers.each(yAxis.ticks, function(tick, index) {
          //     if (index === yAxis.ticks.length - 1) return;
          //     var xPos = yAxis.right;
          //     var yPos = yAxis.getPixelForTick(index);
          //     var xPadding = 10;
          //     // draw tick
          //     ctx.save();
          //     ctx.textBaseline = 'middle';
          //     ctx.textAlign = 'right';
          //     ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
          //     ctx.fillText(tick, xPos - xPadding, yPos + tickGap / 2);
          //     ctx.restore();




          //     var ctx = chart.ctx;
          //     var yAxis = chart.scales['x-axis-0'];
          //     var tickGap = yAxis.getPixelForTick(8) - yAxis.getPixelForTick(0);
          //     yAxis.options.ticks.fontColor = 'transparent'; // hide original tick
          //     // loop through ticks array
          //     Chart.helpers.each(yAxis.ticks, function(tick, index) {
          //        if (index === yAxis.ticks.length - 1) return;
          //        var xPos = yAxis.bottom;
          //        var yPos = yAxis.getPixelForTick(index);
          //        var xPadding = 3;
          //        // draw tick
          //        ctx.save();
          //        ctx.textBaseline = 'middle';
          //        ctx.textAlign = 'bottom';
          //        ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
          //        ctx.fillText(tick, xPos - xPadding, yPos + tickGap / 2);
          //        ctx.restore();
          //  });





        }
     }]
  

    });
}

// var controller = this.chart.controller;
//             var chart = controller.chart;
//             var xAxis = controller.scales['x-axis-0'];
           
//              var	numTicks = xAxis.ticks.length;        
//             var xOffsetStart = xAxis.width / numTicks;
//             var halfBarWidth = (xAxis.width / (numTicks * 2));
    
//             xAxis.ticks.forEach(function(value, index) {
//               var xOffset = (xOffsetStart * index) + halfBarWidth -6;
//               var yOffset = chart.height + 1 ;          
//               ctx.fillText(value, xOffset, yOffset);
//             });
    


chartConfig() {
  // draws a rectangle with a rounded top
  Chart.helpers.drawRoundedTopRectangle = function(
    ctx,
    x,
    y,
    width,
    height,
    radius
  ) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    // top right corner
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    // bottom right	corner
    ctx.lineTo(x + width, y + height);
    // bottom left corner
    ctx.lineTo(x, y + height);
    // top left
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  };
  // @ts-ignore
  const ChartEl: ChartElementsOptionsExtended = Chart.elements;
  // @ts-ignore
  const ChartCo: ChartControllers = Chart.controllers;
  // @ts-ignore
  ChartEl.RoundedTopRectangle = ChartEl.Rectangle.extend({
    draw: function() {
      const ctx = this._chart.ctx;
      const vm = this._view;
      let left, right, top, bottom, signX, signY, borderSkipped;
      let borderWidth = vm.borderWidth;

      if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
      } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
      }

      // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line
      if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        const barSize = Math.min(
          Math.abs(left - right),
          Math.abs(top - bottom)
        );
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        const halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        const borderLeft =
          left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        const borderRight =
          right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        const borderTop =
          top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        const borderBottom =
          bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
          left = borderLeft;
          right = borderRight;
        }
      }

      // calculate the bar width and roundess
      const barWidth = Math.abs(left - right) ;
      const roundness = this._chart.config.options.barRoundness || 0.5;
      const radius = barWidth * roundness * 0.5;

      // keep track of the original top of the bar
      const prevTop = top;

      // move the top down so there is room to draw the rounded top
      top = prevTop + radius;
      const barRadius = top - prevTop;

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // draw the rounded top rectangle
      Chart.helpers.drawRoundedTopRectangle(
        ctx,
        left,
        top - barRadius + 1,
        barWidth,
        bottom - prevTop,
        barRadius
      );

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }
      top = prevTop;
    }
  });

  Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);

  Chart.controllers.roundedBar = ChartCo.bar.extend({
    // @ts-ignore
    dataElementType: ChartEl.RoundedTopRectangle
  });
}



//test 




}





