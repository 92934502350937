import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { MatRadioChange } from '@angular/material';

@Component({
  selector: 'app-upload-excel-data',
  templateUrl: './upload-excel-data.component.html',
  styleUrls: ['./upload-excel-data.component.scss']
})
export class uploadExcelDataDialogComponent implements OnInit {
  selectedOption: string = ''
  policyOptions: string[] = ['Single Policy', 'Fleet Policy'];
  filter: any;
  fileName = '';


  constructor(public service: DashboardService, public dialogRef: MatDialogRef<uploadExcelDataDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private router: Router,private _snackBar: MatSnackBar) {

  }

  ngOnInit() {}

  onFileSelected(event) {

    const file:File = event.target.files[0];

    if (file) {

        this.fileName = file.name;
        const formData = new FormData();
        formData.append("thumbnail", file);

        // const upload$ = this.http.post("/api/thumbnail-upload", formData);
        // upload$.subscribe();
    }
}








  radioChange(event: MatRadioChange) {
   // console.log(event.source.name, event.value);
    this.selectedOption = event.value

}
  
  onProceed=()=>{
    this.dialogRef.close()
    if (this.selectedOption === 'Single Policy') {
      //move to motor single policy underwritting
      console.log("MotorPolicyOption>>>>" + this.selectedOption)
      this.router.navigate(["/motor-quote"])
     

    }else{
      //move to motor fleet policy underwritting
      console.log("MotorPolicyOption>>>>" + this.selectedOption)
      this.router.navigate(["/fleet-motor-quote"])
      
    }
  }
















  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onPinInput=(evt)=>{
    isNaN(+`${evt.target.value}`)
     if(isNaN(+`${evt.target.value}`))
     evt.target.value='';
  }
}
