import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
@Component({
  selector: 'app-manager-passwordset-success',
  templateUrl: './manager-passwordset-success.component.html',
  styleUrls: ['./manager-passwordset-success.component.scss']
})
export class ManagerPasswordsetSuccessComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  loginNow(){
    // swal.showLoading()
    swal.hideLoading()
    swal.fire({
     icon: 'success',
      text: 'Welcome to smartCover..Please login',
      timer: 1000,
     showConfirmButton: false
    }); 
    this.router.navigate(['']);

  }



}
