import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from '../../service/dashboard.service';

@Component({
  selector: 'app-show-premium-total',
  templateUrl: './show-premium-total.component.html',
  styleUrls: ['./show-premium-total.component.scss']
})
export class ShowPremiumTotalComponent implements OnInit {
  constructor(public service: DashboardService, public dialogRef: MatDialogRef<ShowPremiumTotalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.confirmedQuote = data.confirmedQuote;
      this.quotePayload = data.quotePayload;


      this.total_premium=this.quotePayload.currency_code+' '+Number(data.total_premium).toLocaleString('en-GB');
    }
    confirmedQuote:true;
    quotePayload:any;
    total_premium:string;


  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close', data: {confirmedQuote:this.confirmedQuote} });
  }
}
