import { Component, LOCALE_ID, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { DatePipe, formatNumber } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-travel-policy-extension',
  templateUrl: './travel-policy-extension.component.html',
  styleUrls: ['./travel-policy-extension.component.scss']
})
export class TravelPolicyExtension implements OnInit {
 
  start_date: any
  end_date: any
  
  

  constructor(
    public service: DashboardService, 
    public dialogRef: MatDialogRef<TravelPolicyExtension>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string,
    private datePipe: DatePipe

    ) {
      this.start_date = moment(new Date()).format('YYYY-MM-DD')
      this.end_date = this.datePipe.transform(this.service.strDateToDBFormat(this.data.currentArrivalDate), 'yyyy-MM-dd');

    }


  ngOnInit() {
    console.log("data ===> "  + this.data.currentArrivalDate)
  }

  startDateFilter = (date: Date) => {
    return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days') >= 0;
  }

  onStartDateChanged() {
     // this.start_date = this.start_date ? moment(this.start_date).format('YYYY-MM-DD') : null;
      console.log("start_date ===> "  + this.start_date)
  }

  done(){
    if(this.data.dateChange == 'extendArrival' || this.data.dateChange == 'reduceArrival'){
      this.dialogRef.close({ event: 'close', data: {end_date: this.end_date} });
    }else{
      this.dialogRef.close({ event: 'close', data: {start_date: this.start_date} });
    }
   
  }

  endDateFilter = (date: Date) => {
    let diff = moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.data.currentArrivalDate).format('YYYY-MM-DD'), 'days');
    return diff > 0 && diff <= 364;
  }

}
