import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote-benefits',
  templateUrl: './quote-benefits.component.html',
  styleUrls: ['./quote-benefits.component.scss']
})
export class QuoteBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  benefits=[
    {label:'MEDICAL EXPENSES TO INCLUDE:',value:'MAXIMUM LIMIT OF COVER ($50,000.00)'},
    {label:'HOSPITALIZATION',value:'100%'},
    {label:'ATTENDANCE BY A DOCTOR',value:'100%'},
    {label:'EMERGENCY DENTAL',value:'100%'},
    {label:'EMERGENCY OPTICAL',value:'100%'},
    {label:'EMERGENCY EVACUATION/REPATRIATION',value:'100%'},
    {label:'RETURN AFTER MEDICAL TREATMENT',value:'100%'},
    {label:'VISIT BY RELATIVE OR TRAVEL BY COMPANION',value:'100%'},
    {label:'RETURN OF RELATIVE OR TRAVEL BY COMPANION',value:'100%'},
    {label:'RETURN OF ACCOMPANYING CHILDREN',value:'100%'},
    {label:'HOME TRANSPORTATION OF THE DECEASED',value:'100%'},
  ]

  summary="EXCESS ON EACH ITEM ABOVE IS $50.00"
}
