import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { DashboardService } from '../service/dashboard.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

interface Role {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-signupmanager',
  templateUrl: './signupmanager.component.html',
  styleUrls: ['./signupmanager.component.scss']
})
export class SignupmanagerComponent implements OnInit {

  roles: Role[] = [
    {value: 'manager', viewValue: 'Manager'},
    {value: 'admin', viewValue: 'Admin'},
  ];
 
  userdata;

  regm={
    email:'',
    firstName:'',
    lastName:'',
    branch:'',
    new_manager_email:'',
    phone:'',
    role:''
  }


  
  addmanagerform: FormGroup;
  constructor(public formBuilder: FormBuilder, public service: DashboardService, private router:Router) { 
    this.addmanagerform = this.formBuilder.group({
      firstName:new FormControl('',[Validators.required]),
      lastName:new FormControl('',[Validators.required]),
      branch:new FormControl('',[Validators.required]),
      new_manager_email:new FormControl('',[Validators.required]),
      phone:new FormControl('',Validators.compose([Validators.pattern(/^(\+233)[0-9]\d{8}$/),Validators.required])),
      role:new FormControl('',[Validators.required]),


      //managerid: new FormControl('',[Validators.required]),
    })
  }

  ngOnInit() {
    this.userdata=JSON.parse(localStorage.getItem('userdata'));
  }

  regmanager(){
    this.regm = this.addmanagerform.value;

  if(this.addmanagerform.valid) {
     this.regm = this.addmanagerform.value;
     this.regm.email = "appiah@gmail.com"
      var test = JSON.stringify(this.regm);
      // console.log(this.regm);

      swal.showLoading()
      this.service.addManager(this.regm).subscribe(response => {
        // console.log(response);
        swal.hideLoading()
        if (response['responseCode'] === '000') {
          this.addmanagerform = this.formBuilder.group({
            firstName:new FormControl('',[Validators.required]),
            lastName:new FormControl('',[Validators.required]),
            branch:new FormControl('',[Validators.required]),
            new_manager_email:new FormControl('',[Validators.required]),
            phone:new FormControl('',Validators.compose([Validators.pattern(/^(\+233)[0-9]\d{8}$/),Validators.required])),
            role:new FormControl('',[Validators.required]),

            //managerid: new FormControl('',[Validators.required]),
          })
        swal.fire({
          icon:'success',
          title: 'Successful',
        text:'Manager Account Was Created Successfully.',
         footer:'Link has been sent to Managers Email'
        });
        this.router.navigate(['/signupmanager'])

          // swal.hideLoading()
        
        } else {
          swal.fire({
            icon:'warning',
            title: 'Oops...',
            text: response['responseMessage'],
            footer: ''
          });
        }
        
      }, error => {
        swal.fire({
          icon:'error',
          title: 'Oops...',
          text: error,
          footer: ''
        });
        swal.hideLoading()
      })
    } else {
      swal.fire({
        icon:'warning',
        title: 'Oops...',
        text: 'Please fill all form fields',
        footer: ''
      });
    }
    
  }


  registermanager(){
    this.router.navigate(['/signupmanager'])
  }


  allmanagers(){
    this.router.navigate(['/allmanagers'])
  }

}
