import { Injectable } from '@angular/core';
import {Chart} from 'chart.js'

@Injectable()
export class StickerService {


  constructor(){}
  getStickerChart(labels, counts,canvasid){
    const canvas: any = document.getElementById('stickersalesgraph');

    let ctx=null;
    if(canvas)
    ctx = canvas.getContext('2d');

   // var gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
    var gradientFill = ctx.createLinearGradient(0, 0, 0, 170);




gradientFill.addColorStop(0, "rgba(110, 213, 249, 1)");
//gradientFill.addColorStop(.9, "rgba(179, 174, 244, 0.2)");
gradientFill.addColorStop(1, "rgba(179, 174, 244, 0.2)");

// gradientFill.addColorStop(0, "rgba(110, 213, 249, .9)");
// gradientFill.addColorStop(.1, "rgba(110, 213, 249, .9)")
// gradientFill.addColorStop(.2, "rgba(110, 213, 249, .85)");
// gradientFill.addColorStop(.3, "rgba(110, 213, 249, .7)");
// gradientFill.addColorStop(.4, "rgba(110, 213, 249, .7)");
// gradientFill.addColorStop(.5, "rgba(110, 213, 249, .7)");
// gradientFill.addColorStop(.6, "rgba(179, 174, 244, 0.5)");
// gradientFill.addColorStop(.7, "rgba(179, 174, 244, 0.5)");
// gradientFill.addColorStop(.8, "rgba(179, 174, 244, 0.5)");
// gradientFill.addColorStop(.9, "rgba(179, 174, 244, 0.2)");
// gradientFill.addColorStop(1.0, "rgba(179, 174, 244, 0)");

// gradientFill.addColorStop(0, "rgba(110, 213, 249, 1)");
//  gradientFill.addColorStop(0.2, "rgba(110, 213, 249, 1)");
//  gradientFill.addColorStop(0.4, "rgba(110, 213, 249, .65)");
// gradientFill.addColorStop(0.8, "rgba(179, 174, 244, 0.3)");
// gradientFill.addColorStop(1, "rgba(179, 174, 244, 0)");

    return new Chart(canvasid, {


      type: 'line',
      data: {
        labels: labels, //your labels array
        datasets: [
          {
            pointRadius: 0,


            data: counts, //your data array $primary-color
            // borderColor: 'background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);',
            borderColor: "#03a1f9",
            borderWidth:3,
            //   backgroundColor: [
            //     'rgba(200, 200, 235, 1)',
            //     'rgba(255, 99, 132, 1)',
            //     'rgba(75, 192, 192, 1)',
            //     'rgba(153, 102, 255, 1)',
            //     'rgba(255, 159, 64, 1)',
            //     'rgba(94, 107, 46, 1)',
            //     'rgba(107, 46, 58, 1)',
            //     'rgba(107, 46, 58, 1)',
            //     'rgba(208, 103, 152, 1)'

            // ],
            backgroundColor:gradientFill,
            fill: true
          },
          // {
          //   data: canvasid, // your data array
          //   borderColor: '#00AEFF',
          //   fill: false
          // },
        ]
      },

      options: {

        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              drawBorder: false ,//<- set this
              //color: "rgba(0, 0, 0, 0)",
              //drawTicks: false,
               //   display: false,
           },
           ticks: {

            fontFamily :'gotham',
            fontSize: 12,
        },

          }],
          yAxes: [{
            display: true,
            ticks: {
              //fontColor: "rgba(0,0,0,0.5)",
            //  fontfamily : "$font-primary",
              //fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20,
              fontFamily :'gotham',
              fontSize: 12,
          },
          gridLines: {
            drawBorder: false ,//<- set this
            //color: "rgba(0, 0, 0, 0)",
            //drawTicks: false,
             //   display: false,
         },
          }],
        }
      }
    });
}

}





