import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-managerstbl',
  templateUrl: './managerstbl.component.html',
  styleUrls: ['./managerstbl.component.scss']
})
export class ManagerstblComponent implements OnInit {

  userdata;
  managerinfo:any=[];
  searchdata;

  constructor(public service:DashboardService, public router:Router) { }

  ngOnInit() {

    this.userdata=JSON.parse(localStorage.getItem('userdata'))
    this.service.getManagers(this.userdata.email, this.userdata.role).subscribe(data=>{
      this.managerinfo = data;
      
      //this.searchdata = this.quotes;
      
  })
  }

  search (query: string){
    this.searchdata = (query) ?this.managerinfo.filter( data => data.manager_id.toLowerCase().includes(query.toLowerCase()) ||data.lastName.toLowerCase().includes(query.toLowerCase()) || data.firstName.toLowerCase().includes(query.toLowerCase()) || data.email.toUpperCase().includes(query.toUpperCase())) : this.managerinfo;
  }



  registermanager(){
    this.router.navigate(['/signupmanager'])
  }


  allmanagers(){
    this.router.navigate(['/allmanagers'])
  }


}
