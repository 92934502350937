import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatDividerModule, MatToolbarModule, MatIconModule, MatButtonModule, 
  MatMenuModule, MatListModule, MatDatepickerModule, MatFormFieldModule, 
  MatNativeDateModule, MatInputModule,MatPaginatorModule,MatTableModule,
   MatGridListModule, MatTooltipModule} from '@angular/material';
   import {MatSelectModule} from '@angular/material/select';
   import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {RouterModule} from '@angular/router';
import {HighchartsChartModule} from 'highcharts-angular';
import { RevenuetotalComponent } from './widgets/revenuetotal/revenuetotal.component';
import { CashinflowComponent } from './widgets/cashinflow/cashinflow.component';
import { ChequeinflowComponent } from './widgets/chequeinflow/chequeinflow.component';
import { DigichannelComponent } from './widgets/digichannel/digichannel.component';
import { DlytransactComponent } from './widgets/dlytransact/dlytransact.component';
import { DlyrenewpolicyComponent } from './widgets/dlyrenewpolicy/dlyrenewpolicy.component';
import { DlypolicyComponent } from './widgets/dlypolicy/dlypolicy.component';
import { DlystickersalesComponent } from './widgets/dlystickersales/dlystickersales.component';
import { TransactionalgraphComponent } from './widgets/transactionalgraph/transactionalgraph.component';
import { StickersalesgraphComponent } from './widgets/stickersalesgraph/stickersalesgraph.component';
import { ToppeagentgraphComponent } from './widgets/toppeagentgraph/toppeagentgraph.component';
import { PolicygraphComponent } from './widgets/policygraph/policygraph.component';
import { GexampleComponent } from './widgets/gexample/gexample.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { DlyCommissionComponent } from './widgets/dlycommission/dlycommission.component';
@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    RevenuetotalComponent,
    CashinflowComponent,
    ChequeinflowComponent,
    DigichannelComponent,
    DlytransactComponent,
    DlyrenewpolicyComponent,
    DlypolicyComponent,
    DlystickersalesComponent,
    TransactionalgraphComponent,
    StickersalesgraphComponent,
    ToppeagentgraphComponent,
    PolicygraphComponent,
    GexampleComponent,
    ToolbarComponent,
    DlyCommissionComponent
  
    
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatListModule,
    RouterModule,
    HighchartsChartModule,
    MatDatepickerModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatInputModule,
  MatPaginatorModule,
  MatTableModule,
  MatGridListModule,
  ReactiveFormsModule,
  FormsModule,
  MatSelectModule,
  MatTooltipModule
  
      
  ],
  exports:[
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    RevenuetotalComponent,
    CashinflowComponent,
    ChequeinflowComponent,
    DigichannelComponent,
    DlytransactComponent,
    DlyrenewpolicyComponent,
    DlypolicyComponent,
    DlystickersalesComponent,
    TransactionalgraphComponent,
    StickersalesgraphComponent,
    ToppeagentgraphComponent,
    PolicygraphComponent,
    GexampleComponent,
    ToolbarComponent,
    DlyCommissionComponent
    

  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
 
})
export class SharedModule { }
