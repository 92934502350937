import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-resetpassword-expiredlink',
  templateUrl: './manager-resetpassword-expiredlink.component.html',
  styleUrls: ['./manager-resetpassword-expiredlink.component.scss']
})
export class ManagerResetpasswordExpiredlinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
