import { Component, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../service/dashboard.service'
import { MatTableDataSource, MatPaginator } from '@angular/material';
//import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss']
})

export class CommissionComponent implements OnInit {

  constructor( private router: Router, private service: DashboardService) { }

  ngOnInit() {

}


//when buy motor insurance is clicked
onMotorClicked(){
  this.router.navigate(["/motorquote"])
}



}
