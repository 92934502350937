import { Component, OnInit,  ViewChild } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-policylistings',
  templateUrl: './policylistings.component.html',
  styleUrls: ['./policylistings.component.scss']
})
export class PolicylistingsComponent implements OnInit {

  userdata;
  allpolicies:any=[];
  searchdata;

  length: number;
  pageSize: number= 10;
  pageSizeOptions = [ 10, 20,50,100];

  @ViewChild(MatPaginator) paginator: MatPaginator;


  dataSource = new MatTableDataSource();
  displayedColumns = ['no' ,'id', 'firstName', 'phone', 'vehicle_no','Sticker', 'local_policy' , 'nic_policy' ,'date_added']


  constructor(public service:DashboardService, public router:Router) { }

  ngOnInit() {

    this.userdata=JSON.parse(localStorage.getItem('userdata'))
    this.service.getAllpolicies(this.userdata.email, this.userdata.role).subscribe(data=>{
      this.allpolicies = data['responseData'];

      this.dataSource.data = this.allpolicies;
      this.dataSource.paginator = this.paginator;
      
      //this.searchdata = this.quotes;
      
  })
  }

  search (query: string){
    this.searchdata = (query) ?this.allpolicies.filter( data => data.manager_id.toLowerCase().includes(query.toLowerCase()) ||data.lastName.toLowerCase().includes(query.toLowerCase()) || data.firstName.toLowerCase().includes(query.toLowerCase()) || data.email.toUpperCase().includes(query.toUpperCase())) : this.allpolicies;
  }

  Cusinfo(){
    this.router.navigate(['/customers'])
  }
  
  Transactions(){
    this.router.navigate(['/transactions'])
  }
  
  
  newclient(){
    this.router.navigate(['/newcustomer']);
      }
  
    Policylists(){
      this.router.navigate(['/policylistings'])
    }  
  

}
