import { Component, OnInit, ViewChild  } from '@angular/core';
import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-sold-stickers',
  templateUrl: './sold-stickers.component.html',
  styleUrls: ['./sold-stickers.component.scss']
})

export class SoldStickersComponent implements OnInit {

  userdata;
  agent_id;
  stickers:any = [];
  searchdata;

  length: number;
  pageSize: number= 5;
  pageSizeOptions = [ 5,10,20,50,100];


  dataSource = new MatTableDataSource();
  displayedColumns = ['no' ,'client_id', 'sticker_number', 'legacy_policy_number', 'policy_number_nic', 'vehicle_number', 'inception_date', 'expiry_date']

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  // <td>{{customer.client_id}}</td>
  // <td>{{customer.agent_id}}</td>
  // <td>{{customer.created_by}}</td>
  // <td>{{customer.first_name}}, {{customer.last_name}}</td>
  // <td>{{customer.email}}</td>
  // <td>{{customer.phone}}</td>
  // <td>{{customer.res_address}}</td>
  // <td>{{customer.postal_address}}</td>
  // <td>{{customer.date_createdd}}</td>
  // <td>{{customer.date_updatedd}}</td> -->

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  appuser = JSON.parse(localStorage.getItem('appuser'));

  constructor( private router: Router, private service: DashboardService) {
    this.userdata=this.service.currentUser();
   }

  ngOnInit() {

    this.agent_id = this.appuser.code;

     this.service.getAgentStickers(this.agent_id).subscribe(data=>{
      this.stickers = data['responseData'];
       this.searchdata = this.stickers;
       this.dataSource.data =  this.stickers;
       this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;


       console.log(data)

       console.log(this.stickers)
  });

}


search (query: string){
  this.searchdata = (query) ?this.stickers.filter( data => data.client_id.toLowerCase().includes(query.toLowerCase()) || data.last_name.toLowerCase().includes(query.toLowerCase()) || data.date_updated.toLowerCase().includes(query.toLowerCase()) || data.created_by.toLowerCase().includes(query.toLowerCase()) || data.email.toUpperCase().includes(query.toUpperCase())) : this.stickers;
}

Cusinfo(){
  this.router.navigate(['/customers'])
}

Transactions(){
  this.router.navigate(['/transactions'])
}


newclient(){
  this.router.navigate(['/newcustomer']);
    }

  Policylists(){
    this.router.navigate(['/policylistings'])
  }

}
