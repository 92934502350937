import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { MatRadioChange } from '@angular/material';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-travel-dialog',
  templateUrl: './update-travel-dialog.component.html',
  styleUrls: ['./update-travel-dialog.component.scss']
})
export class UpdateTravelOptions implements OnInit {
  selectedOption: string = 'Change policy information'
  updateOptions: any = [
      {"label": "Change policy information", "checked": true},
      {"label": "Extend date of arrival", "checked": false},
      {"label": "Reduce date of arrival", "checked": false}
    ]

  filter: any;

  constructor(public service: DashboardService, public dialogRef: MatDialogRef<UpdateTravelOptions>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private router: Router,private _snackBar: MatSnackBar) {

  }

  ngOnInit() {

  }



  radioChange(event: MatRadioChange) {
    this.selectedOption = event.value.label
}
  
onDone=()=>{
    this.dialogRef.close(
      {
        updateOption: this.selectedOption
      }
    )
  }
















  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onPinInput=(evt)=>{
    isNaN(+`${evt.target.value}`)
     if(isNaN(+`${evt.target.value}`))
     evt.target.value='';
  }
}
