import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/platform-browser";

export class DisableRightClickService {
    constructor(@Inject(DOCUMENT) private document: Document) {}
    disableRightClick() {
      this.document.addEventListener('contextmenu', (event) =>
        event.preventDefault()
      );
    }
  }