import { Component, OnInit , ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../service/dashboard.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';


@Component({
  selector: 'app-newcustomer',
  templateUrl: './newcustomer.component.html',
  styleUrls: ['./newcustomer.component.scss']
})
export class NewcustomerComponent implements OnInit {

  dataSource = new MatTableDataSource();
  displayedColumns = ['no' ,'id', 'agent_id','firstName', 'phone', 'email','date_added']

  userdata;
  newc:any=[];

  message:boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router:Router, private service: DashboardService) { }


ngOnInit() {
  this.userdata=JSON.parse(localStorage.getItem('userdata'))
  /* this.service.newClient(this.userdata.email, this.userdata.role).subscribe(data=>{
    if(data['responseCode']==='000'){
      this.newc = data['responseData'];
      this.dataSource = this.newc;

    }else if(data['responseCode']==='005'){
      // this.message = data['responseData']
      this.message = true;
    }

  }) */
}



Cusinfo(){
  this.router.navigate(['/customers'])
}

Transactions(){
  this.router.navigate(['/transactions'])
}


newclient(){
  this.router.navigate(['/newcustomer']);
    }

  Policylists(){
    this.router.navigate(['/policylistings'])
  }

}
