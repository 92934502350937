import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DashboardService } from '../service/dashboard.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as fs from 'file-saver';


//agentData


// interface agentData {
//   agent_code: number;
//   agent_type: string;
//   agent_marketing_ref: string;
//   agent_time_stampc: string;
//   date_created: string
//   agent_work_mobile: string;
//   agent_first_name: string;
//   agent_family_name: string;
//   agent_manager: string;
//   agent_time_stampm: string,
//   agent_work_email: string;

// }

interface agentData {
  broker_user_code: number,
  broker_user_main_broker_code: string;
  broker_user_email: string;
  broker_user_phone: string;
  broker_user_first_name: string;
  broker_user_last_name: string;
  broker_user_office: string;
  broker_user_status: string;
  broker_user_pin_set: string;
  broker_user_answers_set: string;
  broker_user_created_by: string;
  broker_user_modified_by: string;
  broker_user_date_created: string;
  broker_user_date_modified: string;
  broker_user_disabled_at: null
}








interface GeneralType {
  value: string;
  viewValue: string;
  hide: boolean;
}



interface Branches {
  BRANCH_CODE: string;
  BRANCH_DESC: string;
}
//
interface BranchesResponse {
  responseCode: string;
  responseData: Branches[];
}




@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})




export class ReportComponent implements OnInit {

  userdata;

  broker_user_code: number = 0;
  reportForm: FormGroup;
  currentDateTime: any
  branchesData: Branches[] = [];

  loadedAgents: agentData[];
  filteredAgents: agentData[];

  //agent form
  searchAgents = new FormControl();

  //agent loading
  isAgentLoading: boolean = false;



  excelRep = {
    policy_branch_from: '',
    policy_branch_to: '',
    policy_lob_from: '',
    policy_lob_to: '',
    minDate: '',
    maxDate: '',
    transaction_type: '',
    policy_sync_status: '',
    agent_code: '',
    broker_user_code: ''
    // //branch:'',
    // maxDate:'',
    // transaction_type:'',
    // role:'admin'
  }


  // selectedAgent: agentData = {
  //   agent_code: 0,
  //   agent_type: '',
  //   agent_marketing_ref: '',
  //   agent_time_stampc: '',
  //   date_created: '',
  //   agent_work_mobile: '',
  //   agent_first_name: '',
  //   agent_family_name: '',
  //   agent_manager: '',
  //   agent_time_stampm: '',
  //   agent_work_email: '',
  // }

selectedAgent: agentData = {
    broker_user_code: 0,
    broker_user_main_broker_code: '',
    broker_user_email: '',
    broker_user_phone: '',
    broker_user_first_name: '',
    broker_user_last_name: '',
    broker_user_office: '',
    broker_user_status: '',
    broker_user_pin_set: '',
    broker_user_answers_set: '',
    broker_user_created_by: '',
    broker_user_modified_by: '',
    broker_user_date_created: '',
    broker_user_date_modified: '',
    broker_user_disabled_at: null
  }



  TransactionTypeData: GeneralType[] = [
    { value: 'all', viewValue: 'ALL', hide: false },
    { value: 'Cash', viewValue: 'CASH', hide: false },
    { value: 'Cheque', viewValue: 'CHEQUE', hide: false },
    { value: 'MobileMoney', viewValue: 'DIGITAL CHANNEL', hide: false },
  ];


  PolicyTypeData: GeneralType[] = [
    { value: '1', viewValue: 'SYNCED POLICIES', hide: false },
    { value: '2', viewValue: 'UNSYNCED POLICIES', hide: false },
    { value: '3', viewValue: 'ALL POLICIES', hide: false },
  ];

  ProductData: GeneralType[] = [
    { value: '10', viewValue: 'FIRE', hide: false },
    { value: '20', viewValue: 'MOTOR', hide: false },
    { value: '50', viewValue: 'TRAVEL', hide: false },
  ];

  appuser: any;

  constructor(public formBuilder: FormBuilder,
    public service: DashboardService,
    private router: Router, public datepipe: DatePipe) {

    this.appuser = this.service.currentUser();

  }



  ngOnInit() {

    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    this.initializeReportForm()

    this.showBranches();
    this.loadAgents();

    //on agents Filter
    this.onfilterAgent()
  }




  initializeReportForm() {
    this.reportForm = this.formBuilder.group({
      policy_branch_from: new FormControl('', [Validators.required]),
      policy_branch_to: new FormControl('', [Validators.required]),
      policy_lob_from: new FormControl('', [Validators.required]),
      policy_lob_to: new FormControl('', [Validators.required]),
      minDate: new FormControl('', [Validators.required]),
      maxDate: new FormControl('', [Validators.required]),
      transaction_type: new FormControl('', [Validators.required]),
      policy_sync_status: new FormControl('', [Validators.required]),
      //making agent code optional
      broker_user_code: new FormControl(''),


      // broker_user_email: new FormControl('',[Validators.required]),
      // broker_user_phone: new FormControl('',[Validators.required]),
    });
  }



  onBranchSelectedFrom(code: string) {

    this.excelRep.policy_branch_from = code;

  }



  onBranchSelectedTo(code: string) {

    this.excelRep.policy_branch_to = code;
  }


  onProductSelectedFrom(code: string) {

    this.excelRep.policy_lob_from = code;

  }

  onProductSelectedTo(code: string) {

    this.excelRep.policy_lob_to = code;
  }


  onTransactionTypeSelected(code: string) {

    this.excelRep.transaction_type = code;
  }


  onPolicyTypeSelected(code: string) {

    this.excelRep.policy_sync_status = code;

  }



  onStartDateChanged(dateObject) {
    // convert object to string then trim it to yyyy-mm-dd
    const stringified = JSON.stringify(dateObject.value);


    this.excelRep.minDate = stringified.substring(1, 11);

    // }
  }



  oneEndDateChanged(dateObject) {
    // convert object to string then trim it to yyyy-mm-dd
    const stringified = JSON.stringify(dateObject.value);


    this.excelRep.maxDate = stringified.substring(1, 11);

    // }
  }


  onSelectionChange(agent: agentData) {

    this.selectedAgent = agent
    this.excelRep.agent_code = this.selectedAgent.broker_user_code.toString()



  }


  //Start Date dateFilters
  startDateFilter = (date: Date) => {
    return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days') >= 0;
  }



  // startDateFilter =(date: Date)=>{
  //   return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'),'days')>=0;
  // }
  //End Date dateFilters
  endDateFilter = (date: Date) => {
    let diff = moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.excelRep.minDate).format('YYYY-MM-DD'), 'days');
    return diff > 0 && diff <= 364;
  }




  //fetch branches 
  showBranches() {
    this.service.getBranches()
      .toPromise()
      .then((response: BranchesResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((body: Branches) => {
            this.branchesData.push(body);
          })

        }
      }).catch(err => {
        //  console.log('Body Type load error',err);
      });
  }


  loadAgents() {
    this.filteredAgents = [];
    this.loadedAgents = [];

    const requestPayload = {
      "broker_user_main_broker_code": this.appuser.code.toString(),
    }

    this.service.getBrokerUsers(requestPayload)
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          // console.log("agents>>>> " + JSON.stringify(response.profile))

          response.responseData.map((response: agentData) => {
            this.loadedAgents.push(response);
            this.filteredAgents.push(response);



          })
        }
      });

  }




  onfilterAgent() {
    this.searchAgents.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredAgents = [];
          this.isAgentLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              // let payload = this.loadedAgents.filter((response: agentData) => response.agent_family_name.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              let payload = this.loadedAgents.filter((response: agentData) => response.broker_user_first_name.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isAgentLoading = false
                }),
              )
          }
          else {
            this.isAgentLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: agentData) => {

            this.filteredAgents.push({ ...response, broker_user_first_name: `${response.broker_user_first_name}` });


          })
      })
  }


  //on select agent 
  onSelectAgent(agentData: agentData) {

    this.selectedAgent = agentData;

    this.broker_user_code = agentData.broker_user_code

  }



  onAgentChange(event) {
    if (!event.target.value) {

      this.selectedAgent = {
        broker_user_code: 0,
        broker_user_main_broker_code: '',
        broker_user_email: '',
        broker_user_phone: '',
        broker_user_first_name: '',
        broker_user_last_name: '',
        broker_user_office: '',
        broker_user_status: '',
        broker_user_pin_set: '',
        broker_user_answers_set: '',
        broker_user_created_by: '',
        broker_user_modified_by: '',
        broker_user_date_created: '',
        broker_user_date_modified: '',
        broker_user_disabled_at: null
      }

      if (this.selectedAgent.broker_user_code == 0) {
        this.excelRep.broker_user_code = ''
        this.broker_user_code = 0
      }


    } else {
      //this.filteredModels=[];
    }

  }





  excelReport() {



    if (this.reportForm.valid) {
      this.excelRep = this.reportForm.value;

      //do date 

      const stringified = JSON.stringify(this.excelRep.minDate);
      this.excelRep.minDate = stringified.substring(1, 11);


      const stringifiedMax = JSON.stringify(this.excelRep.maxDate);

      this.excelRep.maxDate = stringifiedMax.substring(1, 11);

      //  console.log("this.excelRep.minDate==" + this.excelRep.maxDate);

      //  console.log("agent code==" + this.excelRep.agent_code);


      //  console.log("lob from ==" + this.excelRep.policy_lob_from);


      //  console.log("lob to ==" + this.excelRep.policy_lob_to);


      //using this logic to allow optional passing of agent code
      var broker_user_code_str = ''
      if (this.broker_user_code != 0) {
        broker_user_code_str = this.broker_user_code.toString()
      }


      if(this.appuser.role == 'broker-user'){
        this.excelRep = {
          policy_branch_from: this.excelRep.policy_branch_from,
          policy_branch_to: this.excelRep.policy_branch_to,
          policy_lob_from: this.excelRep.policy_lob_from,
          policy_lob_to: this.excelRep.policy_lob_to,
          minDate: this.excelRep.minDate,
          maxDate: this.excelRep.maxDate,
          transaction_type: this.excelRep.transaction_type,
          policy_sync_status: this.excelRep.policy_sync_status,
          agent_code: this.appuser.broker_user_main_broker_code.toString(),
          broker_user_code: this.appuser.code.toString(),
  
        }

      }else  if(this.appuser.role == 'broker-admin' || this.appuser.role == 'agent'){
        this.excelRep = {
          policy_branch_from: this.excelRep.policy_branch_from,
          policy_branch_to: this.excelRep.policy_branch_to,
          policy_lob_from: this.excelRep.policy_lob_from,
          policy_lob_to: this.excelRep.policy_lob_to,
          minDate: this.excelRep.minDate,
          maxDate: this.excelRep.maxDate,
          transaction_type: this.excelRep.transaction_type,
          policy_sync_status: this.excelRep.policy_sync_status,
          agent_code: this.appuser.code.toString(),
          broker_user_code: broker_user_code_str,
  
        }
      }else{
        this.excelRep = {
          policy_branch_from: this.excelRep.policy_branch_from,
          policy_branch_to: this.excelRep.policy_branch_to,
          policy_lob_from: this.excelRep.policy_lob_from,
          policy_lob_to: this.excelRep.policy_lob_to,
          minDate: this.excelRep.minDate,
          maxDate: this.excelRep.maxDate,
          transaction_type: this.excelRep.transaction_type,
          policy_sync_status: this.excelRep.policy_sync_status,
          agent_code: this.appuser.code.toString(),
          broker_user_code: broker_user_code_str,
  
        }
      }

    




      swal.showLoading()
      this.service.exportToExcel(JSON.stringify(this.excelRep))
        .subscribe(response => {
          swal.hideLoading()


          //check this logic  
          if (response.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        
            swal.fire({
              title: 'Successful',
              text: "Report generated successfully",
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Export',
              reverseButtons: true,
              allowOutsideClick: false,
              allowEscapeKey: false,

            }).then((result) => {
              if (result.value) {

                this.currentDateTime = this.datepipe.transform((new Date), 'yyyy-MM-dd_HH:mm:ss');

                //export to excel 
                const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, 'Report-' + this.currentDateTime + '.xlsx');



              }
            })


          } else {
            // const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // fs.saveAs(blob, 'Report-'+this.currentDateTime+'.xlsx');

            swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: response['responseMessage'],
              footer: ''
            });

          }

        }, error => {
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error,
            footer: ''
          });
          swal.hideLoading()
        })
    }


    else {

      swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please fill all form fields',
        footer: ''
      });
    }

  }






}
