import { Component, LOCALE_ID, Inject, OnInit, Optional, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { formatNumber } from '@angular/common';
import swal from 'sweetalert2'
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { fromEvent } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-ecobank-checkout',
  templateUrl: './ecobank-checkout.component.html',
  styleUrls: ['./ecobank-checkout.component.scss']
})
export class EcobankCheckoutComponent implements OnInit {
 
  
  appuser: any;
  responseCode: string;
  responseMessage: string;

  timeLeft: number = 60;
  interval;
  OTPExpired = false;
  oneTimePassword = ""

  checkOutUrl:SafeResourceUrl;


  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    public service: DashboardService, 
    public dialogRef: MatDialogRef<EcobankCheckoutComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string,
    private sanitizer: DomSanitizer,
    ) {

      this.appuser = this.service.currentUser();

    }


  ngOnInit() {
    console.log("ecobank payload ->>>xxxx>>>> " + JSON.stringify(this.data))
    this.checkOutUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://pgw.paywithonline.com/r/1067904981002097316')
    console.log("checkOutUrl ===> " + this.checkOutUrl)
  }

  ngAfterViewInit(): void {
    fromEvent(this.iframe.nativeElement, 'load')
      // Skip the initial load event and only capture subsequent events.
      //.pipe(skip(1))
      .subscribe((event: Event) => console.log("url changes ==> " + JSON.stringify(event.target)));
}





  iframeLoadEvent() {
    const currentIframeURL = document.querySelector("iframe").contentWindow;
    console.log("currentIframeURL ===> " + currentIframeURL)

    // if (currentIframeURL.includes("auth/login")) {
    //   swal.fire({
    //     title: '',
    //     text: "Sorry, path to pdf document with code " + this.documentCode + " was not found!",
    //     icon: 'warning',
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,

    //   }).then((result) => {
    //     if (result.value) {
    //       this.location.back()
    //       this.location.back()
    //       // this.router.navigate(["/tables/validations"])
    //     }
    //   })
    // }

  }



  closeDialog(OTPVerifaction:boolean) {
    this.dialogRef.close({ event: 'close', OTPVerifaction: OTPVerifaction});
  }


}
