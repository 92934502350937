import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';

import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';

interface Target {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-new-claim',
  templateUrl: './new-claim.component.html',
  styleUrls: ['./new-claim.component.scss'],
  providers: [DatePipe]
})




export class NewClaimComponent implements OnInit {

  audience: Target[] = [
    {value: 'agent', viewValue: 'Agent'},
    {value: 'customer', viewValue: 'Customer'},
  ];

  selectedFile1: File = null
  selectedFile2: File = null
  selectedFile3: File = null
  selectedPolicy:any
  location:any
  description:any

  policies:any = [];

  policiesPayload={
    client_id:'',
    phone:'',
}

usrType:any;

  quote = JSON.parse(localStorage.getItem('appquote'));
  user = JSON.parse(localStorage.getItem('appuser'));

  constructor(private datePipe: DatePipe, private _formBuilder: FormBuilder, private service: DashboardService, private router: Router, private currencyPipe: CurrencyPipe) {

  }



  ngOnInit() {

    this.policiesPayload.client_id = this.user.client_id
    this.policiesPayload.phone = this.user.phone

    this.loadPolicies()
  
  }

  loadPolicies(){
  
    //swal.showLoading()
    var payload = JSON.stringify(this.policiesPayload)
    console.log(payload)

    this.service.LoadPolicies(payload).subscribe(data=>{
    console.log(data)

      if(data['responseCode'] === '000'){

        this.policies = data['profile'];

     } else{

     }

    
  });

  }


  onUserSelected(itemSelected: string){
    console.log(itemSelected)
    this.selectedPolicy = itemSelected
  }


  onFileSelected(event){
    console.log(event)
    this.selectedFile1 = <File>event.target.files[0]
    this.selectedFile2 = <File>event.target.files[1]
    this.selectedFile3 = <File>event.target.files[2]
  }

  submitClaim(){
    console.log(this.user.client_id)
    const fd = new FormData();
    fd.append('images', this.selectedFile1, this.selectedFile1.name);
    fd.append('images', this.selectedFile2, this.selectedFile2.name);
    fd.append('images', this.selectedFile3, this.selectedFile3.name);
    fd.append('policy_number', this.selectedPolicy);
    fd.append('client_id', "Cl-633353");
    fd.append('description', this.description);

    console.log(fd)
   
    swal.showLoading()
    this.service.NewClaim(fd).subscribe(res=>{
      console.log(res)

      if(res['responseCode'] === '000'){
        swal.hideLoading()
        swal.fire({
          icon: 'success',
           text: 'Your claim request has been submitted successfully. An Agent will contact you shortly.',
           timer: 3000,
          showConfirmButton: false
         });

         this.selectedPolicy = ""
         this.location = ""
         this.description = ""
         this.selectedFile1 = null
         this.selectedFile2 = null
         this.selectedFile3 = null
        
      } else{

        swal.hideLoading()
        swal.fire({ 
          title: 'Failed',
          text: 'Failed to submit claim. Check internet and try again',
          icon: 'error',
        });

     }
      
      
    });
  }

}
