import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manager-expired-link',
  templateUrl: './manager-expired-link.component.html',
  styleUrls: ['./manager-expired-link.component.scss']
})
export class ManagerExpiredLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
