import { Component, LOCALE_ID, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/dashboard.service';
import { formatNumber } from '@angular/common';
import swal from 'sweetalert2'

@Component({
  selector: 'app-payment-otp-verification',
  templateUrl: './payment-otp-verification.component.html',
  styleUrls: ['./payment-otp-verification.component.scss']
})
export class PaymentOTPVerificationComponent implements OnInit {
  appuser: any;
  responseCode: string;
  responseMessage: string;

  timeLeft: number = 60;
  interval;
  OTPExpired = false;
  oneTimePassword = ""


  constructor(
    public service: DashboardService, 
    public dialogRef: MatDialogRef<PaymentOTPVerificationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
    ) {
      this.appuser = this.service.currentUser();
  
     

    }


  ngOnInit() {
    this.startTimer()
    console.log("momo-payment->>>xxxx>>>> " + JSON.stringify(this.data))

  }



  startTimer() {
    this.OTPExpired = false
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
        clearInterval(this.interval);
        this.OTPExpired = true
        this.oneTimePassword = ""
      }
    },1000)
  }


  resendOTP(){
    this.startTimer()

    const payload = {
      "phone": this.data.momo_payment_no
    }

    swal.showLoading()
    this.service.sendOTP(payload)
    .toPromise()
    .then((response: any) => {
      swal.hideLoading()
      console.log("response>>>>>>" + JSON.stringify(response))

      if (response.hasOwnProperty('responseCode') && response.responseCode === '000') {
        swal.fire({
          icon: 'success',
          text: 'One Time Password (OTP) sent to your mobile money number',
          timer: 2000,
          showConfirmButton: true
        });

      } else {
        swal.fire({
          title: '',
          text: response.responseMessage,
          icon: 'error',
        });
      }

    }).catch(error => {
      swal.fire({
        title: '',
        text: error,
        icon: 'error',
      });
    })

  }


  verifyOTP(){
    console.log("verify otp...")

    const payload = {
      "otp": this.oneTimePassword.trim()
    }

    swal.showLoading()
    this.service.verifyOTP(payload)
    .toPromise()
    .then((response: any) => {
      swal.hideLoading()
      console.log("response>>>>>>" + JSON.stringify(response))

      if (response.hasOwnProperty('responseCode') && response.responseCode === '000') {
        swal.fire({
          icon: 'success',
          text: 'One Time Password (OTP) verification successful',
          timer: 2000,
          showConfirmButton: false
        });

        this.closeDialog(true)

      } else {
        swal.fire({
          title: '',
          text: response.responseMessage,
          icon: 'error',
        });
      }

    }).catch(error => {
      swal.fire({
        title: '',
        text: error,
        icon: 'error',
      });
    })



  }



  closeDialog(OTPVerifaction:boolean) {
    this.dialogRef.close({ event: 'close', OTPVerifaction: OTPVerifaction});
  }


}
