import { Component, OnInit} from '@angular/core';
// import {gexampleService} from '../../../modules/service/gexample.service';





@Component({
  selector: 'app-widget-gexample',
  templateUrl: './gexample.component.html',
  styleUrls: ['./gexample.component.scss']
})
export class GexampleComponent implements OnInit {
  constructor() { }

  ngOnInit() {
   
  
  }

 


}
